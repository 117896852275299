import * as HttpService from "./http.service";
import {
  CONTACT_US_LIST_URL,
  GET_ALL_DISPATCHED_ORDERS,
  PODS_MENUS_WITH_DATES_URL,
  SYSTEM_ADMIN_LOGIN_URL,
} from "./url.service";
import {
  PODS_MENUS_URL,
  SCAN_TIMING_REPORTS_URL,
  SCAN_TIMING_DELIEVERD_REPORTS_URL,
} from "./url.service";
export const systemAdminLogin = (mobile) => {
  return HttpService.postWithOutAuth(SYSTEM_ADMIN_LOGIN_URL, { mobile });
};

export const getContactUSList = async (authId, token, offset, limit, search) => {
  return HttpService.getWithAuth(
    CONTACT_US_LIST_URL(authId, offset, limit, search),
    token
  );
};

export const getPodsMenu = async (authId, token, startDate, endDate) => {
  return HttpService.getWithAuth(
    PODS_MENUS_URL(authId, startDate, endDate),
    token
  );
};
export const getPodsMenuWithDates = async (authId, token, startDate, endDate) => {
  return HttpService.getWithAuth(
    PODS_MENUS_WITH_DATES_URL(authId, startDate, endDate),
    token
  );
};
export const getAllScanTimes = async (adminId, date, token) => {
  return HttpService.getWithAuth(SCAN_TIMING_REPORTS_URL(adminId, date), token);
};

export const getAllScanTimesForDelievery = async (
  adminId,
  date,
  token,
  status,
  mealType,
  podId
) => {
  return HttpService.getWithAuth(
    SCAN_TIMING_DELIEVERD_REPORTS_URL(adminId, date, status, mealType, podId),
    token
  );
};

export const getAllDispatchedOrders = async (adminId, token, podId) => {
  return HttpService.getWithAuth(
    GET_ALL_DISPATCHED_ORDERS(adminId, podId),
    token
  );
};
